/*
  Project: Tołpa
  Author: Daniel Kozina
 */

import blockEvent from './modules/block-event';
import blockHero from './modules/block-hero';
import blockInstagram from './modules/block-instagram';
import blockOpinions from './modules/block-opinions';
import blockProducts from './modules/block-products';
import blockProductsSlider from './modules/block-products-slider';
import blockTikTok from './modules/block-tik-tok';

document.addEventListener('DOMContentLoaded', () => {
  blockHero();
  blockTikTok();
  blockInstagram();
  blockProducts();
  blockProductsSlider();
  blockOpinions();
  blockEvent();
});
