import Swiper, { Navigation } from 'swiper';

const blockOpinions = () => {
  const opinionsSlider = document.querySelector('.s-opinions__swiper');

  if (opinionsSlider) {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(opinionsSlider, {
      direction: 'horizontal',
      loop: true,
      modules: [Navigation],
      slidesPerView: 1.05,
      centeredSlides: false,
      spaceBetween: 15,
      navigation: {
        nextEl: '.s-opinions__arrow--next',
        prevEl: '.s-opinions__arrow--prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 130,
        },
      },
    });
  }
};

export default blockOpinions;
