import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper';

const blockHero = () => {
  const hero = document.querySelector('.s-hero');

  if (hero) {
    const pagination = hero.querySelector('.s-hero__slider-pagination');
    const heroSlider = hero.querySelector('.s-hero__slider');

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(heroSlider, {
      direction: 'horizontal',
      modules: [Pagination, EffectFade, Autoplay],
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 3500,
      },
      pagination: {
        el: pagination,
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className}"></span>`;
        },
      },
    });
  }
};

export default blockHero;
