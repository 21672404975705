import Swiper, { Navigation } from 'swiper';

const blockInstagram = () => {
  const tikTokSwiper = document.querySelector('.s-instagram__swiper');

  if (tikTokSwiper) {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(tikTokSwiper, {
      direction: 'horizontal',
      loop: true,
      modules: [Navigation],
      slidesPerView:1,breakpoints:{992:{slidesPerView:3}},
      spaceBetween: 15,
      navigation: {
        nextEl: '.s-instagram__arrow--next',
        prevEl: '.s-instagram__arrow--prev',
      },
    });
  }
};

export default blockInstagram;
