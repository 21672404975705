import Swiper, { Navigation, Pagination } from 'swiper';

const bindAll = () => {
  const menuElements = document.querySelectorAll('[data-tab]');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < menuElements.length; i++) {
    // eslint-disable-next-line no-use-before-define
    menuElements[i].addEventListener('click', change, false);
  }
};

const clear = () => {
  const menuElements = document.querySelectorAll('[data-tab]');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < menuElements.length; i++) {
    menuElements[i].classList.remove('active');
    const id = menuElements[i].getAttribute('data-tab');
    document.getElementById(id).classList.remove('active');
  }
};

const change = (e) => {
  e.preventDefault();
  clear();
  e.target.classList.add('active');
  const id = e.currentTarget.getAttribute('data-tab');
  document.getElementById(id).classList.add('active');
};

const blockProductsSlider = () => {
  bindAll();

  const productsSlider = document.querySelector('.s-products-slider');

  if (productsSlider) {
    const sliders = productsSlider.querySelectorAll(
      '.s-products-slider__slider-box',
    );

    sliders.forEach((element) => {
      const slider = element.querySelector('.s-products-slider__slider');
      const pagination = element.querySelector('.swiper-pagination');
      const prev = element.querySelector('.custom-button-prev');
      const next = element.querySelector('.custom-button-next');

      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(slider, {
        direction: 'horizontal',
        modules: [Pagination, Navigation],
        loop: true,
        spaceBetween: 15,
        slidesPerView: 1.3,
        centeredSlides: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
            centeredSlides: false,
          },
        },
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        pagination: {
          el: pagination,
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        },
      });
    });
  }
};

export default blockProductsSlider;
