const blockEvent = () => {
  document.addEventListener(
    'wpcf7mailsent',
    () => {
      if (typeof fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead');
      }

      // eslint-disable-next-line no-undef
      window.location = tolpaMain.evnetUrl ? tolpaMain.evnetUrl : '';
    },
    false,
  );
};

export default blockEvent;
