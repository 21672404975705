const bindAll = () => {
  const menuElements = document.querySelectorAll('[data-tab]');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < menuElements.length; i++) {
    // eslint-disable-next-line no-use-before-define
    menuElements[i].addEventListener('click', change, false);
  }
};

const clear = () => {
  const menuElements = document.querySelectorAll('[data-tab]');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < menuElements.length; i++) {
    menuElements[i].classList.remove('active');
    const id = menuElements[i].getAttribute('data-tab');
    document.getElementById(id).classList.remove('active');
  }
};

const change = (e) => {
  e.preventDefault();
  clear();
  e.target.classList.add('active');
  const id = e.currentTarget.getAttribute('data-tab');
  document.getElementById(id).classList.add('active');
};

const blockProducts = () => {
  bindAll();
};

export default blockProducts;
